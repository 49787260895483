window.$ = window.jQuery = jQuery;

// activate dropdowns on hover
if (window.matchMedia("(min-width: 1024px)").matches) {
    $('body').on('mouseenter mouseleave', '.dropdown', function(e){
        var $dropdown = $(e.target).closest('.dropdown');
        $dropdown.addClass('show');
        setTimeout(function() {
            $dropdown[$dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
            $('[data-toggle="dropdown"]', $dropdown).attr('aria-expanded', $dropdown.is(':hover'));
        }, 300);
    });
}

$(function(){
    $('.select_2').select2();

    $('.switch-tabs').each(function(){
        var links = $(this).find('.nav-tabs .nav-link');

        links.each(function(){
            $(this).click(function(e){
                e.preventDefault();

                links.each(function(){
                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');

                        var id = '#' + $(this).data('for');
                        $(id).addClass('d-none');
                    }
                });
                    
                $(this).addClass('active');
                var id = '#' + $(this).data('for');
                $(id).removeClass('d-none');
            });
        });
    });

    $('a[href="#"]').click(function(e){
        e.preventDefault();
    });
    $('[data-toggle="popover"]').popover();
});